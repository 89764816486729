import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="221228" />
      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221228_1.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="text-center pb-4">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/221228_2.jpg"
          width={500}
          quality={95}
        />
      </div>
      <div className="font-text-ja">
        <p>天気が良かったので歩いて図書館へ。スタバでバターキャラメルのラテが飲みたかったのに終わってたので、抹茶のドーナツ。抹茶といえば、韓国のモッパンYoutuberのナドちゃんが京都にある辻利という抹茶専門店を紹介していて、そこの抹茶のお菓子が気になってる。</p>
        <p>岩波新書の『豊かさとは何か』を読了。経済大国である日本の豊かさ、貧しさを述べている。西欧と比べて日本の社会保障制度のレベルがいかに低いかを知った。またその問題は日本の経済成長、政治の荒廃、国民性など複雑な層になって起きている。本当の豊かさを実現するには何が必要か考えるきっかけになった。</p>
      </div>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
